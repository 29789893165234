import React from "react";

import "./aboutContent.css"
import uniformlist from "../../assets/global/uniform-list.pdf";

const UniformShopContent = () => (
    <div className="container">
        <div className={"content aboutContent"}>

            <h2>UNIFORM SHOP</h2>

            <p>The St Maroun’s College uniform shop operates on the School grounds during school term days and by
                appointment during school holidays.</p>

            <p>Hours of opening during the term:</p>


            <p>Monday to Friday : 8.00am - 9.00am & 2.00pm - 3.00pm </p>

            <h4>
                <a href={uniformlist}>Uniform Price List</a>
            </h4>
            <p />

            <p>Contact details are:</p>
            <p>Nawal Bazouni 0410 257 623</p>

        </div>
    </div>
)

export default UniformShopContent
