import * as React from "react"
import Layout from "../../components/layout"
import BannerUniformShop from "../../components/about/bannerUniformShop";
import UniformShopContent from "../../components/about/uniformShopContent";


const UniformShopPage = () => {
    return (
        <Layout>
            <BannerUniformShop/>
            <UniformShopContent/>
        </Layout>
    )
}

export default UniformShopPage
