import React from "react";

import "./bannerabout.css"

import firstImage from "../../assets/about/uniformSchoolBags.jpg"

const BannerUniformShop = () => (
    <div className="justify-content-center enrolment">
        <img src={firstImage} className="img-fluid justify-content-center " alt="..."/>
    </div>
)

export default BannerUniformShop
